import noUiSlider from 'nouislider';
import debounce from 'lodash.debounce';
const slidebarSelector = '[data-input-range-slider]';
import AutoNumeric from 'autonumeric';
const inputMinSelector = '[data-min-input]';
const inputMaxSelector = '[data-max-input]';
const inputMinShowSelector = '[data-min-input-show]';
const inputMaxShowSelector = '[data-max-input-show]';

export default class FormRange {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.slidebarEl = this.root.querySelector(slidebarSelector);
        this.minEl = this.root.querySelector(inputMinSelector);
        this.maxEl = this.root.querySelector(inputMaxSelector);

        this.minShowEl = this.root.querySelector(inputMinShowSelector);
        this.maxShowEl = this.root.querySelector(inputMaxShowSelector);

        this.minShowAN = false
        this.maxShowAN = false

        return true;
    }

    init() {
        this.setSlidebars();

        this.settings = JSON.parse(this.root.getAttribute('data-settings'));

        if (this.minShowEl) {
            if (!this.minShowAN) {
                this.minShowEl.value = this.removeNonNumeric(this.minShowEl.value)
                this.minShowAN = new AutoNumeric(this.minShowEl, {
                    digitGroupSeparator: ' ',
                    decimalCharacter: ',',
                    decimalPlaces: 0,
                    modifyValueOnWheel: false,
                });
            }
        }
        if (this.maxShowEl) {
            if (!this.maxShowAN) {
                this.maxShowEl.value = this.removeNonNumeric(this.maxShowEl.value)
                this.maxShowAN = new AutoNumeric(this.maxShowEl, {
                    digitGroupSeparator: ' ',
                    decimalCharacter: ',',
                    decimalPlaces: 0,
                    modifyValueOnWheel: false,
                });
            }
        }
    }

    setSlidebars() {
        if (!this.slidebarEl) return;

        this.settings = JSON.parse(this.root.getAttribute('data-settings'));

        let dataInitMin = parseInt(this.settings.init_min);
        let dataInitMax = parseInt(this.settings.init_max);

        noUiSlider.create(this.slidebarEl, {
            start: [dataInitMin, dataInitMax],
            connect: true,
            step: 1,
            behaviour: 'tap-drag',
            range: {
                min: this.settings.min,
                max: this.settings.max,
            },
        });

        let minEl = this.minEl;
        let maxEl = this.maxEl;
        let minShowEl = this.minShowEl;
        let maxShowEl = this.maxShowEl;

        this.slidebarEl.noUiSlider.on('update', function (values, handle) {
            if (minEl) {
                minEl.value = parseInt(values[0]);
            }
            if (maxEl) {
                maxEl.value = parseInt(values[1]);
            }
            if (minShowEl) {
                minShowEl.value = parseInt(values[0]).toLocaleString('pl-PL');
            }
            if (maxShowEl) {
                maxShowEl.value = parseInt(values[1]).toLocaleString('pl-PL');
            }
        });
        this.slidebarEl.noUiSlider.on('end', function (values, handle) {
            var event = new Event('change')
            if (minEl) {
                minEl.dispatchEvent(event)
            }
            if (maxEl) {
                maxEl.dispatchEvent(event)
            }
            if (minShowEl) {
                minShowEl.value = parseInt(values[0]).toLocaleString('pl-PL');
            }
            if (maxShowEl) {
                maxShowEl.value = parseInt(values[1]).toLocaleString('pl-PL');
            }
        });

        ['change', 'blur'].forEach(eventType => {
            minShowEl.addEventListener(eventType, event => {
                var event = new Event('change')
                this.updateSlidebar(this.slidebarEl.noUiSlider, minShowEl, maxShowEl);
                if (minEl) {
                    minEl.dispatchEvent(event)
                }
                if (maxEl) {
                    maxEl.dispatchEvent(event)
                }
            });
            maxShowEl.addEventListener(eventType, event => {
                var event = new Event('change')
                this.updateSlidebar(this.slidebarEl.noUiSlider, minShowEl, maxShowEl);
                if (minEl) {
                    minEl.dispatchEvent(event)
                }
                if (maxEl) {
                    maxEl.dispatchEvent(event)
                }
            });
        });
    }

    updateSlidebar(slidebar, minEl, maxEl) {
        let range = [];
        if (minEl) {
            let minVal = parseInt(this.removeNonNumeric(minEl.value))
            range.push(minVal);
        }
        if (maxEl) {
            let maxVal = parseInt(this.removeNonNumeric(maxEl.value))
            range.push(maxVal);
        }
        if (range) {
            slidebar.updateOptions({
                start: range,
            });
        }
    }

    disable() {
        this.root.classList.add('-disabled');
        // this.sliderInstance.disabled(true);
        this.slidebarEl.noUiSlider.updateOptions({
            start: [
                parseInt(this.settings.init_min).toLocaleString('pl-PL'),
                parseInt(this.settings.init_max).toLocaleString('pl-PL'),
            ],
        });
    }

    enable() {
        this.root.classList.remove('-disabled');
        this.slidebarEl.noUiSlider.updateOptions({
            start: [
                parseInt(this.settings.init_min).toLocaleString('pl-PL'),
                parseInt(this.settings.init_max).toLocaleString('pl-PL'),
            ],
        });
    }
    
    removeNonNumeric(input) {
        return input.replace(/\D/g, '');
    }
}